/*----start  global classes ------*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    position: relative
}

body {
    font-family: 'Poppins', sans-serif;
    background: rgb(8, 69, 122);
    font-family: 'Roboto', sans-serif;
    font-family: 'Work Sans', sans-serif;
    background: #E6681B !important;
    scroll-behavior: smooth;
}

:root {
    --primary-color: #E6681B;
    --second-color: #085E7D;
    --third-color: #000000;
    --black-color: #636465;
    --white-color: #fff;
}

section {
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
}

.color-primary {
    color: var(--primary-color);
}

*---- navbar------*/

.logo h3 {
    color: var(--white-color);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
}
.logo img {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    object-fit: cover;
}
.social img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}
.icon-bars {
    display: none;
}
.icon-bars svg {
    font-size: 32px;
    color: var(--white-color);
    cursor: pointer;
}
.nav-list {
    list-style: none;
    padding: 10pxs;
    margin: auto;
    transition: all 0.5s ease ;
}
.nav-list li {
    padding:0px 15px
}
.nav-list li a {
    color: var(--white-color); 
    text-decoration: none;
    transition: all .3s ease  ;
    font-weight: 200;
}
.nav-list li a:hover {
    color: var(--primary-color);
}
.box-icons svg {
    color: var(--white-color);
    padding: 0px 2px;
    text-decoration: none;
    transition: all .3s ease;
    margin: 2px;
    width: 40px;
    flex-direction: column;
}
.box-icons a:hover {
    opacity: 0.5;
}
.active {
    display: flex !important;
    transition: all 0.5s ease ;
}
@media (max-width:800px) {
    .navbar-e  {
        flex-direction: column;
    }
    .box-logo {
        width: 100%
    }
    .box-nav-list {
        display: none;
    }
    .nav-list {
        flex-direction: column;
        padding: 10px 0px
    }
    .nav-list li {
       padding: 15px 0px ;
    }
    .icon-bars {
        display: block
    }
    .box-icons {
        display: none;
        margin: 0px;
    }
    .box-icons a {
        padding: 10px 
     }
    
}
.navbar-e {
    position: relative;
    width: 100%;
    z-index: 5555;
    /* transition: all .5s ease; */
    top: 0;
    margin-bottom: 40px;
}
#header-bg {
    background-color:var(--third-color) !important;
}
h1.title {
    font-family: Valerian Handwriting !important;
    font-weight: bold !important;
    color: var(--third-color) !important;
    margin-bottom: 60px !important;
}
/* ----footer---- */
footer {
    background-color: var(--third-color);
    padding: 20px;
}
